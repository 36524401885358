import { DO_NOT_CONTACT, ADD_DNC_COMMENTS, RESET_DNC_DATA } from '../actions/types';

export default function (state = {}, action) {
	switch (action.type) {
		case DO_NOT_CONTACT:
			return action.payload;
		case ADD_DNC_COMMENTS:
			return action.payload;
		case RESET_DNC_DATA:
			return state;
		default:
			return state;
	}
}
