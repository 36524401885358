exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pellaatlowes-inspire-tool-product-design-index-js": () => import("./../../../src/pages/pellaatlowes-inspire-tool/product-design/index.js" /* webpackChunkName: "component---src-pages-pellaatlowes-inspire-tool-product-design-index-js" */),
  "component---src-templates-general-index-js": () => import("./../../../src/templates/general/index.js" /* webpackChunkName: "component---src-templates-general-index-js" */),
  "component---src-templates-offer-index-js": () => import("./../../../src/templates/offer/index.js" /* webpackChunkName: "component---src-templates-offer-index-js" */),
  "component---src-templates-pal-101-index-js": () => import("./../../../src/templates/pal-101/index.js" /* webpackChunkName: "component---src-templates-pal-101-index-js" */),
  "component---src-templates-product-index-js": () => import("./../../../src/templates/product/index.js" /* webpackChunkName: "component---src-templates-product-index-js" */),
  "component---src-templates-support-index-js": () => import("./../../../src/templates/support/index.js" /* webpackChunkName: "component---src-templates-support-index-js" */)
}

