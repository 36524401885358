import React from "react";
import { Provider } from "react-redux";
import configureStore from "./src/components/store";
import flagsmith from 'flagsmith/isomorphic';
import { FlagsmithProvider } from 'flagsmith/react';

import { Speedcurve } from "./src/components/third-party/speedcurve";
import { Eloqua } from "./src/components/third-party/eloqua";
import { GoogleTagManager } from "./src/components/third-party/google-tagmanager";

// eslint-disable-next-line react/display-name, react/prop-types -- gatsby component
export default ({ element }) => {
	const { store } = configureStore();

	flagsmith.init({
		environmentID: process.env.GATSBY_FLAGSMITH_ENVIRONMENT_KEY,
		cacheFlags: true,
	});

	return (
		<Provider store={store}>
			<FlagsmithProvider flagsmith={flagsmith}>
				{element}

				{process.env.NODE_ENV === 'production' && (
					<>
						<Speedcurve />
						<Eloqua />
						<GoogleTagManager />
					</>
				)}
			</FlagsmithProvider>
		</Provider>
	);
};
