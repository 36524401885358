export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

// Location service
export const GET_LOCATIONS = "GET_LOCATIONS";
export const GET_DEFAULT_LOCATION = "GET_DEFAULT_LOCATION";
export const RESET_LOCATIONS = "RESET_LOCATIONS";
export const GET_LOCATION = "GET_LOCATION";
export const LOCATIONS_LOADING = "LOCATIONS_LOADING";
export const GET_MAP_STORES = "GET_MAP_STORES";
export const GET_ALL_STORES = "GET_ALL_STORES";
export const GET_PDSN = "GET_PDSN";

// Ratings and Reviews
export const GET_PDSN_RATING = "GET_PDSN_RATING";
export const GET_PDSN_REVIEWS = "GET_PDSN_REVIEWS";
export const GET_PRODUCT_RATING = "GET_PRODUCT_RATING";
export const GET_PRODUCT_REVIEWS = "GET_PRODUCT_REVIEWS";
export const GET_ALL_PRODUCT_RATINGS = "GET_ALL_PRODUCT_RATINGS";

// User management
export const GET_USER = "GET_USER";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const GET_PCCLOCATIONS = "GET_PCCLOCATIONS";
export const CLEAR_PCC_ERRORS = "CLEAR_PCC_ERRORS";

// Territory
export const GET_ZIPCODE_TERRITORIES = "GET_ZIPCODE_TERRITORIES";

// RTA
export const GET_PHONE_TYPE_LIST = "GET_PHONE_TYPE_LIST";
export const GET_TERRITORY_GROUP = "GET_TERRITORY_GROUP";
export const GET_TIMEZONE_INFO = "GET_TIMEZONE_INFO";
export const HAS_SHOWROOM_APPT = "HAS_SHOWROOM_APPT";
export const GET_CITY_STATE_LIST = "GET_CITY_STATE_LIST";
export const GET_PREFERRED_DAYS = "GET_PREFERRED_DAYS";
export const GET_TIME_SLOTS = "GET_TIME_SLOTS";
export const GET_CONTACT_US_NUMBER = "GET_CONTACT_US_NUMBER";
export const GET_ZIP_DETAILS = "GET_ZIP_DETAILS";
export const GET_BRANCH_ID = "GET_BRANCH_ID";
export const BLOCK_TIMESLOT = "BLOCK_TIMESLOT";
export const UNBLOCK_TIMESLOT = "UNBLOCK_TIMESLOT";
export const GET_NEXT_ID = "GET_NEXT_ID";
export const GET_CAMPAIGN_NAMES = "GET_CAMPAIGN_NAMES";
export const GET_CHANNEL_NAMES = "GET_CHANNEL_NAMES";
export const INSERT_PARTIAL_LEAD = "INSERT_PARTIAL_LEAD";
export const INSERT_APPOINTMENT = "INSERT_APPOINTMENT";
export const INSERT_CONTACT_APPOINTMENT = "INSERT_CONTACT_APPOINTMENT";
export const INSERT_CC_APPOINTMENT = "INSERT_CC_APPOINTMENT";
export const RESET_RTA_DATA = "RESET_RTA_DATA";

// PCR
export const GET_CUSTOMER_APPOINTMENT_LIST = "GET_CUSTOMER_APPOINTMENT_LIST";
export const CHECK_REAL_TIME = "CHECK_REAL_TIME";
export const GET_BRANCH_PHONE = "GET_BRANCH_PHONE";
export const CANCEL_APPOINTMENT = "CANCEL_APPOINTMENT";
export const RESCHEDULE_APPOINTMENT = "RESCHEDULE_APPOINTMENT";
export const KEEP_APPOINTMENT = "KEEP_APPOINTMENT";
export const SEND_BRANCH_EMAIL = "SEND_BRANCH_EMAIL";
export const CANCEL_REASON_LIST = "CANCEL_REASON_LIST";
export const RESET_PCR_DATA = "RESET_PCR_DATA";

// DNC
export const DO_NOT_CONTACT = "DO_NOT_CONTACT";
export const ADD_DNC_COMMENTS = "ADD_COMMENTS";
export const RESET_DNC_DATA = "RESET_DNC_DATA";

// QAS Address Validation
export const QAS_SEARCH = "QAS_SEARCH";
export const QAS_FORMAT = "QAS_FORMAT";

//brochures
export const BROCHURES_PAGE = "BROCHURES_PAGE";
export const CLEAR_BROCHUREDATA = "CLEAR_BROCHUREDATA";
