import React from 'react';
import { Script } from 'gatsby';

export const Eloqua = () => (
	<Script
		id='eloqua-script'
		key='eloqua'
	>
		{`
			var _elqQ = _elqQ || [];
			_elqQ.push(['elqSetSiteId', '1920192983']);
			_elqQ.push(['elqUseFirstPartyCookie', 'tracking.pella.com']);
			_elqQ.push(['elqTrackPageView']);

			var s = document.createElement('script');
			s.async = true;
			s.src = '//img03.en25.com/i/elqCfg.min.js';
			document.head.appendChild(s);
		`}
	</Script>
);
